<template>
  <div class="modalPopup check_modal">
    <div class="modal_header">
      <h3 class="title">설비연동</h3>
      <button class="modal_close" @click="modalClose"></button>
    </div>
    <select-machines
      v-if="stepIndex == 0"
      :counterSensorList="counterSensorList"
      :stepIndex="stepIndex"
      @selectSensorInfo="selectSensorInfo = $event"
    ></select-machines>
    <record-machines
      v-if="stepIndex > 0"
      :stepIndex="stepIndex"
      :runningPlcList="selectSensorInfo"
      :isCounterInjection="isCounterInjection"
      @managementDataQuntity="$emit('insertmanagementDataQuntity', $event)"
    ></record-machines>
    <div class="modal_footer">
      <button
        v-if="stepIndex > 1"
        class="btn_sub2"
        @click="stepIndex == 2 ? (stepIndex = 1) : (stepIndex = 2)"
      >
        {{ stepIndex == 2 ? '연동시작' : '중지' }}
      </button>
      <button
        class="btn_sub2"
        :disabled="stepIndex == 0 && selectSensorInfo.length == 0"
        @click="
          stepIndex == 0
            ? (stepIndex = 1)
            : stepIndex == 1
            ? (stepIndex = 2)
            : modalClose()
        "
      >
        {{ stepIndex == 0 ? '선택' : stepIndex == 1 ? '중지' : '연동종료' }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import SelectMachines from '@/layouts/components/productionInterLockingModalComponents/SelectMachines';
import RecordMachines from '@/layouts/components/productionInterLockingModalComponents/RecordMachines';

export default {
  mixins: [FETCH_MIXIN, SPINNER_MIXIN, DRAG_MODAL_MIXIN],
  components: {
    SelectMachines,
    RecordMachines,
  },
  props: {
    counterSensorList: { type: Array, require: true },
    managementData: { type: Object, require: true },
  },
  data() {
    return {
      selectSensorInfo: [],
      stepIndex: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      machine: 'getMachines',
    }),
    isCounterInjection() {
      if (this.counterSensorList.length > 0) {
        const findInjection = this.findInfoFromId(
          this.machine,
          this.counterSensorList[0].machine.id,
        );
        if (findInjection.name.includes('사출기')) {
          return true;
        } else return false;
      } else return false;
    },
  },
  methods: {
    modalClose() {
      this.$emit('onclose');
      this.stepIndex = 0;
      this.selectSensorInfo = [];
    },
    insertManagementData() {},
  },
  async created() {
    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', '센서');
    }
    if (this.stepIndex != 0) {
      this.stepIndex = 0;
      this.selectSensorInfo = [];
    }
  },
};
</script>

<style scoped></style>
