var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[_c('div',{staticClass:"contents"},[(_vm.target_lot != null)?_c('div',{staticClass:"order_info_list"},[_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("상태")]),_c('input',{attrs:{"type":"text","placeholder":"","readonly":""},domProps:{"value":_vm.findInfoFromId(_vm.lot_types, this.target_lot.lot_type_id).name}})]),_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("생산제품")]),_c('input',{attrs:{"type":"text","placeholder":"생산제품","readonly":""},domProps:{"value":_vm.findInfoFromId(_vm.products, _vm.target_lot.product_id).name}})]),(_vm.target_lot.product_id != null)?_c('button',{staticClass:"btn_sub2",on:{"click":function($event){return _vm.goBomManagementPage(_vm.target_lot.product_id)}}},[_vm._v(" BOM ")]):_vm._e()]),_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("완료일정")]),_c('input',{attrs:{"type":"text","placeholder":"YYYY-MM-DD","readonly":""},domProps:{"value":_vm.target_lot.deadline_date}})])]),_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("납품처")]),_c('input',{attrs:{"type":"text","placeholder":"납품처","readonly":""},domProps:{"value":_vm.findInfoFromId(_vm.companys, _vm.target_lot.company_id).name}})])]),_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("작업수량("+_vm._s(_vm.findInfoFromId( _vm.units, _vm.findInfoFromId(_vm.products, _vm.target_lot.product_id).stock_unit_id ).name)+")")]),_c('input',{attrs:{"type":"text","placeholder":"작업수량","readonly":""},domProps:{"value":("" + (_vm.$makeComma(_vm.target_lot.quantity)) + (_vm.findInfoFromId(
                  _vm.units,
                  _vm.findInfoFromId(_vm.products, _vm.target_lot.product_id)
                    .stock_unit_id
                ).name))}})])]),_c('div',[_c('div',{staticClass:"input_text"},[_c('label',[_vm._v("LOT번호")]),_c('input',{attrs:{"type":"text","placeholder":"LOT번호","readonly":""},domProps:{"value":_vm.findInfoFromId(_vm.lot_types, _vm.target_lot.lot_type_id).detail !=
              'wait process'
                ? _vm.target_lot.lot_number
                : ''}})]),(
            _vm.findInfoFromId(_vm.lot_types, _vm.target_lot.lot_type_id).detail !=
              'wait process'
          )?_c('button',{staticClass:"btn_sub2",on:{"click":function($event){return _vm.trackingLotNum(_vm.target_lot.lot_number)}}},[_vm._v(" 추적 ")]):_vm._e()]),_c('div',[(
            _vm.target_lot.lot_type_id ==
              _vm.lot_types.find(function (x) { return x.detail == 'processing'; }).id &&
              _vm.validChk &&
              _vm.isPermissionOn
          )?_c('button',{staticClass:"btn_sub1",on:{"click":_vm.doneProcess}},[_vm._v(" 작업 완료처리 ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"progress_contents",class:{
        card_5:
          _vm.target_lot != null
            ? _vm.target_lot.bom_info.bom_process.length - 1 <= 5
              ? true
              : false
            : false,
      }},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.target_lot != null
              ? _vm.target_lot.bom_info.bom_process
              : []),function(lot_process,index){return _c(_vm.dynamicComponent,{key:lot_process.id + _vm.keyCountForReDraw,tag:"component",attrs:{"process_id":lot_process.process_id,"process_name":_vm.findInfoFromId(_vm.process, lot_process.process_id).name,"sub":lot_process.index,"id":("chartData" + (lot_process.id)),"is_last_process":index == _vm.target_lot.bom_info.bom_process.length - 1,"lot_quantity":_vm.target_lot.quantity,"wait_quantity":_vm.getWaitQuantity(lot_process),"production_info":lot_process.production_info,"lot_id":_vm.target_lot.id,"product_id":_vm.target_lot.product_id,"bom_process_id":lot_process.id,"lot_type_id":_vm.target_lot.lot_type_id,"package_process_yn":_vm.findInfoFromId(_vm.process, lot_process.process_id)
                .package_process_yn,"product_group_yn":_vm.findInfoFromId(_vm.products, _vm.target_lot.product_id).product_group_yn},on:{"oninsert":function($event){return _vm.insertNewSeq($event, lot_process.process_id)},"ondelete":function($event){_vm.target_lot.lot_type_id ==
              _vm.lot_types.find(function (x) { return x.detail == 'done process'; }).id
                ? _vm.checkAndDeleteProduct($event)
                : _vm.checkValidDeleteSeq($event)},"ondeletePackage":function($event){return _vm.checkAndDeleteProduct($event)}}})}),1)]),_vm._m(0)])]),(_vm.showLotSearch)?_c('lot-search',{on:{"onclose":function($event){return _vm.hideLotSearch()},"onselect":function($event){return _vm.selectLot($event)}}}):_vm._e(),(_vm.showResourceReturn)?_c('resource-return',{attrs:{"target_lot":_vm.target_lot,"remain_materials":_vm.target_lot.custom_input_yn ? _vm.getNotUsedResourceList : null},on:{"onClose":function($event){_vm.showResourceReturn = false},"onSelect":function ($event) {
        _vm.returnResource($event);
        _vm.showResourceReturn = false;
      }}}):_vm._e(),(_vm.showResourceReturn)?_c('div',{staticClass:"modal_layer"}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide_btn_wrap"},[_c('button',{staticClass:"button-prev",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-angle-left"})]),_c('button',{staticClass:"button-next",attrs:{"type":"button"}},[_c('i',{staticClass:"fa fa-angle-right"})])])}]

export { render, staticRenderFns }