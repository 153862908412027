<template>
  <div>
    <div class="modal_body">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th></th>
              <th>설비기기명</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in counterSensorList" :key="index">
              <td>
                <div class="input_checkbox">
                  <label
                    class="chk_box"
                    :for="`checkbox${index}`"
                    :class="{ active: checkMachine.includes(index) }"
                    @click="selectMachine(index)"
                  >
                    <i class="fa fa-check"></i>
                  </label>
                  <input type="checkbox" :id="`checkbox${index}`" />
                </div>
              </td>
              <td>{{ item.machine.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';

export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    counterSensorList: { type: Array, require: true },
    stepIndex: { require: true },
  },
  data() {
    return {
      checkMachine: [],
    };
  },
  methods: {
    selectMachine(index) {
      const includeIndex = this.checkMachine.findIndex(x => x == index);
      if (includeIndex != -1) {
        this.checkMachine.splice(includeIndex, 1);
      } else {
        this.checkMachine.push(index);
      }

      const filterSensorList = this.lodash
        .clonedeep(this.counterSensorList)
        .filter((x, index) => this.checkMachine.includes(index));

      this.$emit('selectSensorInfo', filterSensorList);
    },
  },
  watch: {
    stepIndex(newValue) {
      if (newValue != 0) {
        this.checkMachine = [];
      }
    },
  },
};
</script>

<style scoped></style>
