<template>
  <div>
    <div class="modal_body machine_record_from">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>No</th>
              <th>설비기기명</th>
              <th>{{ isCounterInjection ? '캐비티수' : '통과수량' }}</th>
              <th v-if="isCounterInjection">생산수량</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getCounterSensorList" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{
                  sensor_list != null
                    ? item.pass_counter[0].plc_sensor_name.split(' - ')[0]
                    : ''
                }}
              </td>
              <td>
                {{
                  sensor_list != null
                    ? isCounterInjection
                      ? $makeComma(item.pass_counter[0].cavities)
                      : $makeComma(item.pass_counter[0].value)
                    : ''
                }}
              </td>
              <td v-if="isCounterInjection">
                {{
                  sensor_list != null
                    ? $makeComma(item.pass_counter[0].value)
                    : ''
                }}
              </td>
            </tr>
            <tr>
              <td colspan="2">총 생산수량</td>
              <td colspan="2">
                {{
                  $makeComma(
                    runningPlcLogPassCounterList
                      .map(x => x.value)
                      .reduce((a, b) => $decimalAdd(a, b), 0),
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <three-button-modal
        :modal_title="'수량(중량)카운터 초기화여부'"
        :modal_content="
          runningPlcLogAllList.filter(x => x.value > 0).length > 0
            ? `연동하려는 설비 <br />'${runningPlcLogAllList
                .filter(x => x.value > 0)
                .map(x => x.plc_sensor_name)
                .join(
                  ',<br />',
                )}'<br />의 카운터 값이 '0' 이상입니다.<br/><br/> 값을 '0'으로 초기화하고<br/>진행하시겠습니까? `
            : ''
        "
        :show_modal="showThreeButtonModal"
        @onYes="
          resetAndProcessingCounter();
          showThreeButtonModal = false;
        "
        @onNo="
          processingCounter();
          showThreeButtonModal = false;
        "
        @onClose="showThreeButtonModal = false"
      ></three-button-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import SPINNER_MIXIN from '@/mixins/spinner';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import ThreeButtonModal from '@/layouts/components/ThreeButtonModal';

export default {
  mixins: [FETCH_MIXIN, SPINNER_MIXIN, DRAG_MODAL_MIXIN],
  props: {
    stepIndex: { require: true },
    runningPlcList: { type: Array, require: true },
    isCounterInjection: { type: Boolean, require: true },
  },
  components: {
    ThreeButtonModal,
  },
  data() {
    return {
      isCounterSensorProcess: true,
      sensor_list: null,

      // plc flag
      running_plc: false,
      is_plc_running_ok: true,
      just_running: false,
      timer: 1,
      counter_interval: null,
      resetTime: null,

      showThreeButtonModal: false,

      //
      manageDataPassQuantity: 0,
      manageDataFailQuantity: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensors: 'getPlcSensor',
      target_lot: 'getTargetLotFromProduction',
    }),
    getCounterSensorList() {
      // (3)(4)
      // 카운터 센서
      if (this.sensor_list != null) {
        let nowMachine = this.lodash.clonedeep(this.runningPlcList);
        let plcCounterList = [];

        if (this.isCounterInjection) {
          // 연동기기가 사출기일 경우
          nowMachine.forEach(el => {
            const productionSensorName = this.findInfoFromId(
              this.plc_sensors,
              el.pass_counter[0],
            ).name.split(' - ')[0];

            const cavitiesSensor = this.lodash
              .clonedeep(this.plc_sensors)
              .find(
                x =>
                  x.name.split(' - ')[0] == productionSensorName &&
                  x.name.includes('캐비티수'),
              ).id;

            const cavitiesSensorLog = this.lodash
              .clonedeep(this.sensor_list)
              .filter(x => cavitiesSensor == x.plc_sensor_id);

            let productionLog = this.lodash
              .clonedeep(this.sensor_list)
              .filter(x => el.pass_counter[0] == x.plc_sensor_id);
            productionLog[0].cavities = cavitiesSensorLog[0].value;

            plcCounterList.push({
              all: productionLog,
              pass_counter: productionLog,
              cavities: cavitiesSensorLog,
            });
          });
        } else {
          // 연동기기가 양품, 불량 카운터일 경우
          nowMachine.forEach(el => {
            const pass_id = el.counter_value
              .filter(x => !x.poor_counter_yn)
              .map(x => x.counter_id);
            const fail_id = el.counter_value
              .filter(x => x.poor_counter_yn)
              .map(x => x.counter_id);

            const passCountSensors = this.lodash
              .clonedeep(this.sensor_list)
              .filter(x => pass_id.includes(x.plc_sensor_id));
            const failCountSensors = this.lodash
              .clonedeep(this.sensor_list)
              .filter(x => fail_id.includes(x.plc_sensor_id));

            plcCounterList.push({
              all: passCountSensors.concat(failCountSensors),
              pass_counter: passCountSensors,
              fail_counter: failCountSensors,
            });
          });
        }

        return plcCounterList;
      } else {
        const retrunNullObject = !this.isCounterInjection
          ? [
              {
                all: [],
                pass_counter: [],
                fail_counter: [],
              },
            ]
          : [
              {
                all: [],
                pass_counter: [],
                cavities: [],
              },
            ];
        return retrunNullObject;
      }
    },
    runningPlcLogAllList() {
      let makeAllList = this.getCounterSensorList
        .map(x => x.all)
        .reduce((a, b) => a.concat(b), []);

      return makeAllList;
    },
    runningPlcLogPassCounterList() {
      let makePassCounterList = this.getCounterSensorList
        .map(x => x.pass_counter)
        .reduce((a, b) => a.concat(b), []);
      return makePassCounterList;
    },
  },
  methods: {
    async resetAndProcessingCounter() {
      this.showSpinner();
      await this.$store
        .dispatch('RESET_PLC_COUNTER_VALUE', {
          address_list: this.runningPlcLogAllList.map(
            x => this.plc_sensors.find(y => y.id == x.plc_sensor_id).address,
          ),
        })
        .then(response => {
          this.resetTime = new Date();
          console.log(response);
          this.just_running = true;
          this.is_plc_running_ok = true;
          this.running_plc = true;
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('오류', '설비 값 초기화 중 오류 발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    processingCounter() {
      this.just_running = true;
      this.is_plc_running_ok = true;
      this.running_plc = true;
    },
    async INSERT_COUNTER_LOG() {
      let insertCounterList = [];
      if (this.isCounterInjection) {
        insertCounterList = this.lodash
          .clonedeep(this.runningPlcLogAllList)
          .map(x => {
            return {
              lot_id: this.target_lot.id,
              product_id: this.target_lot.product_id,
              value: x.value,
              plc_sensor_detail_type_id: x.plc_sensor_detail_type_id,
              plc_sensor_id: x.plc_sensor_id,
              cavities: x.cavities,
            };
          });
      } else {
        insertCounterList = this.lodash
          .clonedeep(this.runningPlcLogAllList)
          .map(x => {
            return {
              lot_id: this.target_lot.id,
              product_id: this.target_lot.product_id,
              value: x.value,
              plc_sensor_detail_type_id: x.plc_sensor_detail_type_id,
              plc_sensor_id: x.plc_sensor_id,
            };
          });
      }
      this.INSERT_WITH_PAYLOAD(
        'INSERT_COUNTER_LOG',
        { data_list: insertCounterList },
        '계수기 점유',
      );
    },
    async UPDATE_COUNTER_LOG() {
      await this.$store
        .dispatch('FETCH_COUNTER_LOG_NOW')
        .then(data_list => {
          if (data_list.length < 1) {
            // this.openOneButtonModal('오류', '연동 종료 중 오류발생');
          } else {
            let temp = this.lodash.clonedeep(data_list);
            temp = temp.map(x => {
              const nowVal = this.runningPlcLogAllList.find(
                y => y != undefined && y.plc_sensor_id == x.plc_sensor_id,
              );
              if (nowVal != undefined) {
                return {
                  id: x.id,
                  value: nowVal.value,
                };
              } else {
                return {
                  id: x.id,
                  value: 0,
                };
              }
            });
            this.UPDATE_WITH_PAYLOAD('UPDATE_COUNTER_LOG', { data_list: temp });
          }
        })
        .catch(error => {
          console.log('error', error);
          this.openOneButtonModal('오류', '연동 종료 중 오류발생');
        });
    },
    async FETCH_SENSOR_LOG_LIST_NOW_CHECK() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(sensors => {
          // FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION는
          // v_plc_sensor_log_now의 데이터를 가져온다
          this.sensor_list = sensors;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async FETCH_SENSOR_LOG_LIST_NOW() {
      await this.$store
        .dispatch('FETCH_PLC_SENSOR_CURRENT_LOG_TO_PRODUCTION')
        .then(async sensors => {
          this.sensor_list = sensors;
          await this.changeRowValue(sensors);
        })
        .catch(error => {
          console.log(error);
        });
    },
    changeRowValue(sensors) {
      if (sensors != undefined) {
        this.sensor_list = sensors;
      }

      if (this.runningPlcLogAllList.length > 0) {
        if (this.runningPlcLogPassCounterList.length > 0) {
          if (
            this.resetTime == null ||
            new Date(this.runningPlcLogPassCounterList[0].create_time) >
              this.resetTime
          ) {
            const sumPassQuantity = this.lodash
              .clonedeep(this.runningPlcLogPassCounterList)
              .map(x => x.value)
              .reduce((a, b) => this.$decimalAdd(a, b), 0);

            this.manageDataPassQuantity = sumPassQuantity;
            this.resetTime = null;
          } else {
            this.manageDataPassQuantity = 0;
          }
        }
        if (!this.isCounterInjection) {
          const failList = this.lodash
            .clonedeep(this.getCounterSensorList)
            .map(x => x.fail_counter)
            .reduce((a, b) => a.concat(b));

          if (failList.length > 0) {
            if (
              this.resetTime == null ||
              new Date(failList[0].create_time) > this.resetTime
            ) {
              const sumFailQuantity = this.lodash
                .clonedeep(failList)
                .map(x => x.value)
                .reduce((a, b) => this.$decimalAdd(a, b), 0);

              this.manageDataFailQuantity = sumFailQuantity;
              this.resetTime = null;
            } else {
              this.manageDataFailQuantity = 0;
            }
          } else {
            this.manageDataFailQuantity = 0;
          }
        }
      }
    },
    changeRowValueZero() {
      if (this.runningPlcLogAllList.length > 0) {
        if (this.runningPlcLogPassCounterList.length > 0) {
          this.manageDataPassQuantity = 0;
        }
        if (!this.isCounterInjection) {
          const failList = this.lodash
            .clonedeep(this.getCounterSensorList)
            .map(x => x.fail_counter)
            .reduce((a, b) => a.concat(b));

          if (failList.length > 0) {
            this.manageDataFailQuantity = 0;
          } else {
            this.manageDataFailQuantity = 0;
          }
        }
      }
    },
  },
  watch: {
    // plc연동 시작
    async running_plc(newValue, oldValue) {
      this.showSpinner();
      await this.FETCH_SENSOR_LOG_LIST_NOW_CHECK();
      if (oldValue == false && newValue == true) {
        // plc연동하게 되면
        const valueCheck = this.lodash
          .clonedeep(this.runningPlcLogAllList)
          .filter(x => x.value > 0);
        if (valueCheck.length == 0 || this.just_running) {
          this.is_plc_running_ok = true;
          this.counter_interval = setInterval(() => {
            this.timer -= 1;
          }, 1000);
          await this.INSERT_COUNTER_LOG();
          if (this.just_running) {
            this.just_running = false;
            await this.changeRowValue();
          } else {
            await this.changeRowValueZero();
          }
        } else {
          // plc_sensor_log에 해당 센서 value가 0이상일경우
          // 초기화 할지 물어봄
          this.showThreeButtonModal = true;
          this.just_running = false;
          this.is_plc_running_ok = false;
          this.running_plc = false;
        }
      } else {
        if (this.is_plc_running_ok) {
          // 위 조건과 다르지만 plc가 가동중이면?
          clearInterval(this.counter_interval);
          await this.UPDATE_COUNTER_LOG();
        }
      }
      this.hideSpinner();
    },
    async timer(newValue) {
      if (newValue == 0) {
        await this.FETCH_SENSOR_LOG_LIST_NOW();
        this.timer = 10;
      }
    },
    stepIndex(newValue, oldValue) {
      if (oldValue == 1 && newValue == 2) {
        clearInterval(this.counter_interval);
        this.timer = 1;
        this.running_plc = false;
        this.just_running = false;
        this.is_plc_running_ok = false;

        const emitData = {
          pass_quantity: this.manageDataPassQuantity,
          fail_quantity: this.manageDataFailQuantity,
        };
        this.$emit('managementDataQuntity', emitData);
      } else if (oldValue == 2 && newValue == 1) {
        this.processingCounter();
      }
    },
  },
  async destroyed() {
    clearInterval(this.counter_interval);
    await this.UPDATE_COUNTER_LOG();
  },
  async created() {
    this.running_plc = true;
    if (this.target_lot != null) {
      await this.FETCH_NO_SPINNER(
        'FETCH_PRODUCT_OCCUPIED_STOCK',
        '제품 사용중 재고',
      );
    }
  },
};
</script>

<style scoped></style>
