var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"modal_body machine_record_from"},[_c('div',{staticClass:"mes_tbl_wrap"},[_c('table',{staticClass:"mes_tbl"},[_c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("설비기기명")]),_c('th',[_vm._v(_vm._s(_vm.isCounterInjection ? '캐비티수' : '통과수량'))]),(_vm.isCounterInjection)?_c('th',[_vm._v("생산수량")]):_vm._e()])]),_c('tbody',[_vm._l((_vm.getCounterSensorList),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sensor_list != null ? item.pass_counter[0].plc_sensor_name.split(' - ')[0] : '')+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.sensor_list != null ? _vm.isCounterInjection ? _vm.$makeComma(item.pass_counter[0].cavities) : _vm.$makeComma(item.pass_counter[0].value) : '')+" ")]),(_vm.isCounterInjection)?_c('td',[_vm._v(" "+_vm._s(_vm.sensor_list != null ? _vm.$makeComma(item.pass_counter[0].value) : '')+" ")]):_vm._e()])}),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("총 생산수량")]),_c('td',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm.$makeComma( _vm.runningPlcLogPassCounterList .map(function (x) { return x.value; }) .reduce(function (a, b) { return _vm.$decimalAdd(a, b); }, 0) ))+" ")])])],2)])]),_c('three-button-modal',{attrs:{"modal_title":'수량(중량)카운터 초기화여부',"modal_content":_vm.runningPlcLogAllList.filter(function (x) { return x.value > 0; }).length > 0
          ? ("연동하려는 설비 <br />'" + (_vm.runningPlcLogAllList
              .filter(function (x) { return x.value > 0; })
              .map(function (x) { return x.plc_sensor_name; })
              .join(
                ',<br />'
              )) + "'<br />의 카운터 값이 '0' 이상입니다.<br/><br/> 값을 '0'으로 초기화하고<br/>진행하시겠습니까? ")
          : '',"show_modal":_vm.showThreeButtonModal},on:{"onYes":function($event){_vm.resetAndProcessingCounter();
        _vm.showThreeButtonModal = false;},"onNo":function($event){_vm.processingCounter();
        _vm.showThreeButtonModal = false;},"onClose":function($event){_vm.showThreeButtonModal = false}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }